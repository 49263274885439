'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import clsx from 'clsx';
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from '@skybeam/shared-ui-kit/ui/sheet';
import { Button } from '@skybeam/shared-ui-kit/ui/button';
import { ExternalLink, Menu } from 'lucide-react';
import {
  OrganizationSwitcher,
  SignedIn,
  SignedOut,
  UserButton,
} from '@clerk/nextjs';
import Image from 'next/image';
import CampaignListButton from './CampaignListButton';
import { HELP_CENTER_URL } from '../../../../src/const';

interface ActiveLinkProps extends React.ComponentPropsWithRef<typeof Link> {
  pathname: string;
}

function ActiveLink(props: ActiveLinkProps) {
  const { className, href } = props;
  const pathname = usePathname();
  const isActive = pathname === href || pathname.startsWith(`${href}/`);
  const textColor = isActive
    ? 'text-white font-strong bg-gray-700 px-4 rounded-full'
    : 'text-white font-strong px-4 hover:bg-gray-700 hover:rounded-full';
  if (href === '/campaigns' || href === '/') {
    return (
      <CampaignListButton
        data-test-campaigns-link-btn
        className={clsx(textColor, className)}
      >
        Campaigns
      </CampaignListButton>
    );
  }
  return <Link {...props} className={clsx(textColor, className)} />;
}

const CAMPAIGN_SETUP_PATH_PATTERN =
  /^\/campaigns\/(?:\d|-|[a-zA-Z])+\/(?:build|summary)$/;

export default function MainNav() {
  const pathname = usePathname();

  const isDisplayed =
    !CAMPAIGN_SETUP_PATH_PATTERN.test(pathname) &&
    !pathname.startsWith('/campaigns/clone');

  return (
    isDisplayed && (
      <div className="sticky top-0 z-10 mb-10">
        <header className="flex h-20 items-center justify-between gap-4 border-b bg-gray-800 px-4 md:px-6">
          <nav className="hidden flex-col text-lg font-medium md:flex md:flex-row md:items-center md:text-sm">
            <CampaignListButton className="flex items-center gap-2 pr-10 text-lg font-semibold md:text-base">
              <Image
                alt="Skybeam"
                src="/skybeam_logo_dark.svg"
                width={127}
                height={31}
              />
              <span className="sr-only">Limelight</span>
            </CampaignListButton>
            <SignedIn>
              <ActiveLink
                href="/campaigns"
                className="transition-colors"
                pathname={pathname}
              >
                Campaigns
              </ActiveLink>
              <ActiveLink
                href="/insights"
                className="py-2 transition-colors"
                pathname={pathname}
              >
                Insights
              </ActiveLink>
              <ActiveLink
                href={HELP_CENTER_URL}
                target="_blank"
                className="flex items-center py-2 transition-colors"
                pathname={pathname}
              >
                Help Center
                <ExternalLink className="ml-1 size-4 text-gray-400" />
              </ActiveLink>
            </SignedIn>
          </nav>
          <Sheet>
            <SheetTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                className="shrink-0 bg-gray-800 text-white md:hidden"
              >
                <Menu className="size-5" />
                <span className="sr-only">Toggle navigation menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="bg-gray-800">
              <nav className="grid gap-6 bg-gray-800 text-lg font-medium">
                <Link
                  href="/"
                  className="flex items-center gap-2 text-lg font-semibold md:text-base"
                >
                  <Image
                    alt="Skybeam"
                    src="/skybeam_logo_dark.svg"
                    width={127}
                    height={31}
                    className="mb-2"
                  />
                  <span className="sr-only">Limelight</span>
                </Link>
                <SignedIn>
                  <ActiveLink
                    href="/campaigns"
                    className="transition-colors"
                    pathname={pathname}
                  >
                    Campaigns
                  </ActiveLink>
                  <ActiveLink
                    href="/insights"
                    className="py-2 transition-colors"
                    pathname={pathname}
                  >
                    Insights
                  </ActiveLink>
                  <ActiveLink
                    href={HELP_CENTER_URL}
                    target="_blank"
                    className="flex items-center py-2 transition-colors"
                    pathname={pathname}
                  >
                    Help Center
                    <ExternalLink className="ml-1 size-4 text-gray-400" />
                  </ActiveLink>
                </SignedIn>
              </nav>
            </SheetContent>
          </Sheet>

          <nav
            data-test-navigation
            className="flex items-center gap-6 text-sm font-medium"
          >
            <SignedOut>
              <ActiveLink
                href="/sign-in"
                className="transition-colors"
                pathname={pathname}
              >
                Sign in
              </ActiveLink>
              <ActiveLink
                href="/sign-up"
                className="transition-colors"
                pathname={pathname}
              >
                Sign up
              </ActiveLink>
            </SignedOut>
            <SignedIn>
              <OrganizationSwitcher
                hidePersonal
                organizationProfileMode="navigation"
                organizationProfileUrl="/settings/users"
                afterCreateOrganizationUrl={'/campaigns'}
                afterSelectOrganizationUrl={'/campaigns'}
                hideSlug
                appearance={{
                  layout: {
                    unsafe_disableDevelopmentModeWarnings: true,
                  },
                  elements: {
                    organizationSwitcherTrigger:
                      'text-white font-medium active:text-white focus:text-white hover:text-white hover:bg-gray-700 px-4 rounded-full',
                    organizationSwitcherPopoverActionButton__manageOrganization:
                      {
                        display: 'none',
                      },
                  },
                }}
                organizationProfileProps={{
                  appearance: {
                    layout: { unsafe_disableDevelopmentModeWarnings: true },
                  },
                }}
              />
              <UserButton
                userProfileMode="navigation"
                userProfileUrl="/settings/general"
                appearance={{
                  layout: { unsafe_disableDevelopmentModeWarnings: true },
                }}
              />
            </SignedIn>
          </nav>
        </header>
      </div>
    )
  );
}
